.flexDirectionColumn {
    flex-direction: column;
}

.textCenter {
    text-align: center;
}

.mainImg {
    width: 100%;
    border-radius: 25%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.25s;
	transform: scale(1)
}

.mainImg2 {
    width: 100%;
    border-radius: 2%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.25s;
	transform: scale(1)
}

.mainImg:hover {
    transform: scale(1.05);
}

.marginAuto {
    margin: auto !important;
}

.mainParalax {
    background-attachment: fixed; /* Fixe l'image pendant le scroll */
    background-size: cover; /* L'image prendra 100% de l'espace, quitte à la crop */
    background-position: center; /* Centre la position de l'image */
    background-repeat: no-repeat; /* Empêche l'image de se répéter en fonction de la taille d'écran */
    background-image: url("../../src/asset/img/TourmalineParalax.png");
    height: 200px;
}

.paralaxRubis {
    background-attachment: fixed; /* Fixe l'image pendant le scroll */
    background-size: cover; /* L'image prendra 100% de l'espace, quitte à la crop */
    background-position: center; /* Centre la position de l'image */
    background-repeat: no-repeat; /* Empêche l'image de se répéter en fonction de la taille d'écran */
    background-image: url("../../src/asset/img/Artistic/bandeau.png");
    height: 200px;
}

.endPage {
    padding-bottom: 25px;
}

.marginBot45{
    margin-bottom: 45px !important;
}

.mainImgContainer {
    margin: auto !important;
    padding: 45px 45px 15px 45px !important; 
}

.containerText {
    padding: 0px 30px !important;
    text-align: justify;
}

.title {
    font-size: calc(16px + 0.5vw);
    font-weight: bold;
    padding: 30px;
    line-height: calc(20px + 0.5vw);
}

.imgSlider {
    height: 100%;
}

.mainContainerWhoami{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: white;
    padding: 0 !important;
}

@media (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .responsiveCont {
        flex-direction: column-reverse;
    }
}

.popover  {
    max-width: none !important; 
    padding: 5px;
}

.popoverAbout {
    color: black;
}

.popoverAbout:hover {
    color: grey;
}

.popoverBtn {
    background: white !important;
    color: black !important;
    border: none !important;
    padding: 0 !important;
    vertical-align: unset !important;
}

.popoverBtn:hover {
    background: white !important;
    color: gray !important;
    border: none !important;
    text-decoration: underline;
}

.popoverBtn:focus{
    outline: none !important;
    box-shadow: none !important;
}

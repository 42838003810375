

.titleSection {
    text-align: center;
    font-size: calc(16px + 1vw);;
    margin: 26px !important;
}

.containerTitleExpertise {
    border-left: 1px solid black;
    border-right: 1px solid black;
    margin: 0 !important;
}

.containerSection {
    border: 1px solid black;
    margin: 0 !important;
}

.containerImgExpertise {
    width: 100%;
}

.textExpertise{
    text-align: center;
    /* font-size: calc(12px + 0.8vw); */
}

.textExpertiseTitle {
    text-align: center;
    font-size: calc(12px + 0.4vw);
    font-weight: 700;
}

.expertiseImage {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
}

.overlayExpertise {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: calc(60px + 5vw);
    color: white;
    background: rgba(0,0,0,0.35);
    font-family: 'GreatVibes', 'Montserrat', 'sans-serif';
}

.mainParalax2 {
    background-attachment: fixed; /* Fixe l'image pendant le scroll */
    background-size: contain; /* L'image prendra 100% de l'espace, quitte à la crop */
    background-position: center; /* Centre la position de l'image */
    background-repeat: no-repeat; /* Empêche l'image de se répéter en fonction de la taille d'écran */
    background-image: url("../../src/asset/img/expertise/heliodoreBig.png");
    height: 200px;
}

@media (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .mainParalax2 {
        background-attachment: fixed; /* Fixe l'image pendant le scroll */
        background-size: contain; /* L'image prendra 100% de l'espace, quitte à la crop */
        background-position: center; /* Centre la position de l'image */
        background-repeat: no-repeat; /* Empêche l'image de se répéter en fonction de la taille d'écran */
        background-image: url("../../src/asset/img/expertise/heliodoreBigMobile.png");
        height: 200px;
    }
}

.underlineText {
    font-weight: bold;
}

.containerPrice {
    border: 1px solid black;
    padding: 30px 50px !important;
    margin-bottom: 35px;
    margin-top: 15px !important;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 90px;
}

.imgBanniere{
    max-height: 500px;
    overflow: hidden;
}

.titleList {
    margin-bottom: 10px !important;
}
@media only screen and (min-width: 578px) {
    .navbar > .container{
      flex-direction: column;
    }

    .logoSolo {
      height: 50px;
    }


}

@media (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .logoSolo {
      height: 40px;
    }
    
  .align-for-mobile {
    padding: 0.375rem 0.75rem !important;
  }
}

.navbarContent{
  border-bottom: 1px solid black;
  background: white;
  font-weight: 500;
}

.navLinkStyle{
  color: rgba(0, 0, 0, 0.808);
  font-weight: bold;
  text-decoration: unset !important;
}

.navLinkStyle:hover{
  color: var(--main-color-backup) !important;
  background: linear-gradient(to right, var(--main-color-dark) 0%, var(--main-color-light) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navLinkStyle:active {
  background-color: white !important;
}

.linkMail {
  cursor: pointer;
}

.linkMail:hover {
  color: #9e9595;
}
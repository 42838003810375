
.footerContainer {
    background: white;
    box-shadow: rgb(0 0 0 / 30%) 0px 14px 13px;
    /* box-shadow: 0px 5px 200px #00C0FF */
    padding-top: 25px;
    text-align: center;
}

.titleHeader {
    font-weight: bold;
}

.headerLineTitle {
    margin-bottom: 15px;

}

.headerLine {
    margin-bottom: 15px;
}

.footerLink, .footerLink > a{
    color: #000 !important;
    text-decoration: none !important;
    cursor: pointer;
}

.footerLink:hover,.footerLink > a:hover {
    color: gray !important;
}
.modalDesktop {
    width: 70% !important;
    max-width: 70% !important;
}

.modalMobile {
    width: 97% !important;
}
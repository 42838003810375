
.titleGallery {
    text-align: center;
    font-size: calc(16px + 3vw);
    padding-top: 35px;
}

.mainContainerGallery { 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: white;
}

.containerPlaceholder {
    position: relative;
}

@keyframes placeHolderShimmer {
    0%{ background-position: -468px 0; }
    100%{ background-position: 468px 0; }
}

.ici {
    cursor: pointer;
    text-decoration: underline;
}

.ici:hover {
    color: gray;
}

.animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }

.placeholderCustom{ 
    margin: 0px;
    position: absolute;
    height: 92%;
    width: 92%;
    top: 4%;
    left: 4%;
    border-radius: 5px;
}

.visibilityCustom {
    display: none !important;
}
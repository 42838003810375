

.artisticCadre {
    border: 1px solid black;
    padding: 30px 50px !important;
    margin-top: 30px !important;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 90px;
}

@media (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */

    .artisticCadre {
        padding: 30px 20px !important;
        margin-left: 15px;
        width: 92% !important;
    }
}

.videoOuter {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.center {
    text-align: center;
}

.video {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
}

.containerLeft {
    padding: 0px 0px 0px 30px !important;
    text-align: justify;
}

.containerRight {
    padding: 0px 30px 0px 0px !important;
    text-align: justify;
}

.popoverWord {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.popoverWord:hover {
    color: grey;
}

.tooltip .tooltip-inner {
    max-width: none; 
    padding: 15px;
}

.linkArtistic {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.linkArtistic2 {
    color: white !important;
    text-decoration: unset !important;
}


.uploadedImg {
    width: 100%;
    transform: scale(1);
    border-radius: 5px;
}

.uploadedImgContainer {
    position: relative;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.uploadedImgContainer:hover {
    transform: scale(1.05);
}

.binUploadedImg {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
    border-radius: 20px;
    transition: 0.25s;
}

.modifyImg {
    position: absolute;
    top: 50px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
    border-radius: 5px;
    transition: 0.25s;
}

.binUploadedImg:hover, .modifyImg:hover {
    transform: scale(2);
}

.explication {
    text-align: center;
    font-size: 20px;
    margin-bottom: 15px;
}

.buyContainer {
    position: absolute;
    width: calc(12px + 2vw);
    height: calc(12px + 2vw);
    background: rgba(255,255,255,0.6);
    border-radius: 50%;
    bottom: 5%;
    right: 5%;
    padding: 8px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.buyContainer:hover {
    transform: scale(1.15);
}

@media (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .buyContainer {
        width: calc(24px + 2vw);
        height: calc(24px + 2vw);
        border-radius: 50%;
        bottom: 10%;
        right: 10%;
        padding: 0px;
    }

    .buyContainer:hover {
        transform: scale(1);
    }

    .uploadedImgContainer:hover {
        transform: scale(1.0);
    }
}
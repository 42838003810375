

.containerMainTitleUnivers {
    padding-top: 45px;
    padding-bottom: 45px;
    flex-direction: column;
}

.mainTitleUnivers {
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
    font-size: 35px;
    margin-bottom: 15px;
}   

.textMainTitle {
    font-size: calc(16px + 0.23vw);
    text-align: center;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.multiColorAnimation{
    background: linear-gradient(-45deg, var(--main-color-dark), var(--main-color-light), var(--main-color-primary));
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.titleUnivers {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: -15px;
}

.subTitleUnivers {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-bottom: 30px;
}

.containerUniversImage {
    padding: 30px !important;
}

.UniversImage {
    width: 100%;
    height: auto;
    box-shadow: 0 1px 1px rgb(0 0 0 / 11%), 0 2px 2px rgb(0 0 0 / 11%), 0 4px 4px rgb(0 0 0 / 11%), 0 6px 8px rgb(0 0 0 / 11%), 0 8px 16px rgb(0 0 0 / 11%);
    border-radius: 15px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.25s;
}
.UniversImage:hover{
    transform: scale(1.05);
}

.overlayTitle {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 10px black;
    font-family: 'GreatVibes';
    background: rgba(0, 0, 0, 0.35);
}

.nameTitle {
    font-size: calc(14px + 6vw);
}

.jobTitle {
    font-size: calc(14px + 3vw);
    margin-bottom: 5%;
}

@keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
  }

.iconSocialNetwork {
    width: 50px;
    height: 50px;
    filter: drop-shadow(0px 0px 0.5px #000)
            drop-shadow(0px 0px 0.5px #000)
            drop-shadow(0px 0px 0.5px #000)
            drop-shadow(0px 0px 0.5px #000);
    cursor: pointer;
}

.iconSocialNetwork:hover {
    animation: shake 0.3s cubic-bezier(.36,.07,.19,.97) both;
}

@media (min-width:320px) and (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .iconSocialNetwork {
        width: 25px;
        height: 25px;
    }
    
}

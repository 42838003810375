
#root {
    /* background-color: var(--main-bg-purple); */
    font-family: 'Montserrat';
    background: linear-gradient(45deg, rgba(189,110,252,0.35) 0%, rgba(94,155,255,0.35) 35%, rgba(189,110,252,0.35) 78%, rgba(219,18,152,0.35) 100%);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* https://codepen.io/P1N2O/pen/pyBNzX */

:root {
    --main-color-dark: #510ac9;
    --main-color-light: #5e9bff;
    --main-color-primary: #db1298;
    --main-color-backup: #8071ff;
    --main-bg-purple: rgba(77, 5, 232, 1);
    --main-bg-color: #f5f5f5;
}

@font-face {
    font-family: 'GreatVibes';
    src: url("../asset/fonts/GreatVibes-Regular.ttf");
}

@font-face {
    font-family: 'Montserrat';
    src: url("../asset/fonts/Montserrat-VariableFont_wght.ttf");
}

.frontImage {
    width: 100%;
}

.dropdown-menu {
    margin-top: 0 !important;
}

.btn-hide {
    font-family: 'Montserrat';
    background: white !important;
    border-color: white !important;
    color: rgba(0, 0, 0, 0.608) !important;
    font-weight: 700 !important;
    line-height: 1.6 !important;
    box-shadow: none !important;
}

.btn-hide:hover {
    color: var(--main-color-backup) !important;
    background: linear-gradient(to right, var(--main-color-dark) 0%, var(--main-color-light) 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: white !important;
}

.mainContainer {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: white;
}
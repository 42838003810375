.programmeTitle {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}

.titleList {
    margin-bottom: 10px !important;
}

.linkEducation {
    cursor: pointer;
}

.linkEducation:hover {
    color: gray;
}

.paralaxEducation {
    background-attachment: fixed; /* Fixe l'image pendant le scroll */
    background-size: cover; /* L'image prendra 100% de l'espace, quitte à la crop */
    background-position: center; /* Centre la position de l'image */
    background-repeat: no-repeat; /* Empêche l'image de se répéter en fonction de la taille d'écran */
    background-image: url("../../src/asset/img/education/paralax.png");
    height: 200px;
}

.imgFormation {
    width: 100%;
    border-radius: 5px;
    vertical-align: unset !important;
}

.containerEducation {
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 90px;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
    margin-bottom: 25px !important;
}

.containerAvailable {
    padding-bottom: 75px !important;
}

.contEdu {
    padding: 5px 15px 5px 15px !important;
}

.titleEducation {
    font-size: 18px;
    font-weight: bold;
}

.buttonEducation {
    background-color: #000 !important;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px !important;
    margin: 10px;
    width: 100%;
    border: unset !important;
}

.buttonEducation:focus{
    outline: none !important;
    box-shadow: none !important;
}

.price {
    margin: auto !important;
    text-align: right !important;
    padding-right: 30px !important;
    font-size: 25px;
    font-weight: bold;
}

.available {
    margin: auto !important;
    text-align: right !important;
    padding-right: 30px !important;
    padding-bottom: 15px;
    font-size: 25px;
    font-weight: bold;
}

.containerabs {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.linkEducation2 {
    color: white !important;
    text-decoration: none;
} 

.containerPrice { 
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 36px;
}

.line {
    border-bottom: 1px solid black;
    width: 33%;
    margin: 10px auto !important;
}

.containerBlog {
    width: 640px;
    margin: 10px auto !important;

}

.blog {
    margin: 15px 0px !important;
}

.post {
    width: 100%;
    margin: 10px auto !important;
}

.titleBlog {
    text-align: center;
    font-size: 24px;
    margin-top: 25px;
}

.pagActive {
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
}

.pag {
    font-size: 18px;
    margin: auto 0 !important;
    cursor: pointer;
}

.containerPagin {
    width: 250px !important;
    text-align: center;
    margin: 0 auto;
}

@media (max-width: 577px)  { /* smartphones, iPhone, portrait 480x320 phones */

    .post {
        width: 33%;
        margin: 10px auto !important;
    }

    .containerBlog {
        width: 100%;
    }

    .postContainer {
        text-align: center;
    }

}